import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import {
  addFileSetting,
  getCategories,
  getFileSetting,
  updateFileSetting,
} from "../../reducers/commonReducer";
import { home } from "../../const";
import FileSettingAction from "./FileSettingAction";

const FileSettingList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("slug") === "admin" ? !localStorage.getItem("x-admin-token") : !localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
    dispatch(getFileSetting());
    dispatch(getCategories())
  }, []);

  const { getFileSettingList } = useSelector((state) => state.commonReducer);
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <FileSettingAction
          title={"Add Setting"}
          popUpTitle="Add Setting"
          api={addFileSetting}
        />
        <table className="table mt-2">
          <thead className="normalplace">
            <tr className="tables">
              <th className="col-1">Sr.</th>
              <th className="over-col-size">Name</th>
              <th className="over-col-size">Slug</th>
              <th className="col-3">Description</th>
              <th className="col-3">Category</th>
              <th className="col-1">Status</th>
              <th className="col-1">Action</th>
            </tr>
          </thead>
          <tbody className="tbody-table">
            {!!getFileSettingList &&
              getFileSettingList?.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr key={data?._id}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}</td>
                      <td>{data?.slug}</td>
                      <td>{data?.description}</td>
                      <td>{!!data?.category && data?.category?.map((cat, catIndex) => <span key={catIndex}>{`${cat?.name}, `}</span>)}</td>
                      <td>{data?.status ? <span className="text-white bg-success rounded px-2 f-13">Active</span> : <span className="text-white bg-danger rounded px-2 f-13">InActive</span>}</td>
                      <td>
                        <FileSettingAction
                          title={<i className="bi bi-pencil-square"></i>}
                          popUpTitle="Update Setting"
                          api={updateFileSetting}
                          id={data?.id}
                          name={data?.name}
                          description={data?.description}
                          status={data?.status}
                          category={data?.category}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FileSettingList;
