import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {deleteUserAddress, getUserAddressDetails, updateUserAddress} from "../../../reducers/commonReducer";
import UpdateAddress from "./UpdateAddress";
import Footer from "../../Sub/Footer";
import CommonHeader from "../../Sub/CommonHeader";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { addUserAddress } from "../../../const";
import Offers from "./Offers";
import { orderCreate } from "../../../reducers/orderDetailsReducer";
import AddUserAddress from "./AddUserAddress";
import GotoTop from "../../../Components/GotoTop";

const UserAddress = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const amount = queryParameters.get("amount");
  const addNew = queryParameters.get("addNew");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [coupon, setCoupon] = useState();
  const [billingShippingStatus, setBillingShippingStatus] = useState(true);
  const [couponStatus, setCouponStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [selectedAddressIndex, setSelectedAddressIndex] = useState();
  const [billingStatusLocal, setBillingStatusLocal] = useState();

  useEffect(() => {
    setLoading(true);
    dispatch(getUserAddressDetails());
    setLoading(false);
  }, []); 
  //billing status
  const handleChildData = (data) => {
    setBillingStatusLocal(data?.status);
    setBillingShippingStatus(true);
  };
  //select address
  const handleSelect = (address, index) => {
    setSelectedAddressIndex(index);
  };
  //coupon amount and Qty
  // const updateCouponAmountStatus = (amount, status) => {
  //   setCoupon(amount);
  //   setCouponStatus(status);
  // };

  // Retrieve network information
  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
  const networkSpeed = connection ? `${connection.downlink} Mbps` : "Unknown";
  const connectionType = connection ? connection.effectiveType : "Unknown";

  const { getUserAddressList, getCartlistData } = useSelector((state) => state.commonReducer);
  const discount = (coupon / 100) * amount
  let firstServicableIndex;
  //bydefault select serverable address
    useEffect(() => {
      firstServicableIndex = !!getUserAddressList && getUserAddressList?.findIndex(address => address?.servicable);
      if((firstServicableIndex === 0) && getUserAddressList?.[firstServicableIndex]?.servicable){
       setSelectedAddressIndex(0);
      }
      if(!getUserAddressList?.[firstServicableIndex]?.servicable){
        setSelectedAddressIndex(firstServicableIndex);
      }
      if (firstServicableIndex !== -1) {
        setSelectedAddressIndex(firstServicableIndex);
      }
    }, [getUserAddressList]);
    //redirect to add address
    useEffect(() => {
      if(!loading && getUserAddressList?.length === 0 && addNew !== "true"){
        navigate(`/add-user-address?amount=${amount}`)
      }
    },[loading, getUserAddressList])
    //updateAddress
    const updateUserEmailAddress = async(email, id, index) => {
      const apiResp = await dispatch(updateUserAddress({email, id, index}))
      //console.log("apiResp?.payload?.status", apiResp?.payload?.status      )
      if(apiResp?.payload?.status){
        dispatch(getUserAddressDetails());
      }
    }
  const billingFullAddress = JSON.parse(localStorage.getItem("billingFullAdrs"));
  return (
    <>
      <CommonHeader />
      <div className="container-fluid address">
        {/* <Row>
          <Col md={12}> 
            {amount && amount !== "null" ? <p className="f-18 mt-2 mb-0 text-center"><strong>Order Summary: <span><b>{amount}/-</b></span></strong></p> : null}
            {
              discount > 1 && <div className="border py-2 mb-2 rounded">
              <h6 className="text-success text-center">🎁🎉Congratulations you got <b>{coupon}%</b> Discount.🎁🎉</h6>
              <h6 className="text-info text-center mb-0">Total Payable Amount: <span className="text-danger"><b>{amount}-{discount?.toFixed(0)} = {(amount - discount)?.toFixed(0)}/-</b></span></h6>
              </div>
            }
            <Offers orderAmount={amount} couponRate={coupon} couponTypeStatus= {couponStatus} couponAmountStatus = {updateCouponAmountStatus}/>
            <hr/>
          </Col>
        </Row> */}
        <Row className="">
          <Container>
            <div className="section__head d-flex justify-content-between mb-40">
              <div className="section__title">
                <h2 className="mt-3">
                  <b>My Address</b>
                </h2>
              </div>
              <Link to={`${addUserAddress}?amount=${amount}`} aria-label="Add New Address">
                <Button className="theme-btn mt-2">Add New Address</Button>
              </Link>
            </div>
          </Container>
        </Row>
        {loading ? (
          <div className="align-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <Row>
            {getUserAddressList?.map((address, index) => (
              <Col key={index} md={4} className={`mt-2 mb-2`}>
                  <Container onClick={() => address?.servicable && handleSelect(address, index)} className={(!address?.servicable ? `border-danger text-dark` : null) || (selectedAddressIndex === index ? 'border-success text-dark rounded' : '')}>
                    <div className="row pb-2 cursor-pointer shadow pt-2">
                        {!address?.servicable && <Row>
                          <Col><h6><b className="text-danger">**We currently do not serve this area. Try using a different address**</b></h6></Col>
                        </Row>}
                        <Row className="align-item-center">
                          <Col md={8} sm={8} xs={8} className="d-flex">
                          <input 
                              type="radio" 
                              name="addressRadio" 
                              className="m-1"
                              checked={selectedAddressIndex === index} 
                              onChange={() => address?.servicable && handleSelect(address, index)} 
                            />
                            <p className="mb-0 f-18">
                              <strong>
                                {address?.firstName + " " + (address?.lastName || "")}
                              </strong>
                            </p>
                          </Col>
                          <Col md={4} sm={4} xs={4} className="text-end d-flex justify-content-end">
                            <UpdateAddress
                              title={"Update Address"}
                              firstName={address?.firstName}
                              lastName={address?.lastName}
                              addressline1={address?.addressline1}
                              addressline2={address?.addressline2}
                              city={address?.city}
                              zipcode={address?.zipcode}
                              state={address?.state}
                              phone={address?.phone}
                              alternateNumber = {address?.alternateNumber}
                              email={address?.email}
                              indexId={index}
                              id={address?._id}
                              houseNo= {address?.houseNo}
                            />
                            <Button
                              variant="danger"
                              className="rounded ml-2 py-0 px-1"
                              onClick={async (e) => {
                                const apiResp = await dispatch(
                                  deleteUserAddress({ id: address?._id, index })
                                );
                                // navigate(`/add-user-address?amount=${amount}`)
                                if(apiResp?.payload?.status){
                                  const addressApiResp = await dispatch(getUserAddressDetails())
                                  if(addressApiResp?.payload?.status){
                                    !addressApiResp?.payload?.address?.length && navigate(`/add-user-address?amount=${amount}`)
                                  }
                                }
                              }}
                            >
                              <i className="bi bi-trash3-fill"></i>
                            </Button>
                          </Col>
                        </Row>
                        <address className="mb-0 f-16">
                          {!!address?.houseNo && <><b>{address?.houseNo}</b><br/></>}
                          <b>
                            {address?.addressline1 +
                              " " +
                              (address?.addressline2 ? address?.addressline2 : '')}
                            <br />{" "}
                            {address?.city +
                              "-" +
                              address?.zipcode +
                              "," +
                              address?.state}
                          </b>
                        </address>
                        <p className="mb-0 f-16">
                          <strong>Phone: </strong>
                          {address?.phone}
                        </p>
                       {address?.alternateNumber && <p className="mb-0">
                          <strong>Alternate Number: </strong>
                          {address?.alternateNumber}
                        </p>}
                        {address?.email ? <p className="mb-0">
                          <strong>Email: </strong>
                          {address?.email}
                        </p> : null}
                        {/* {address?.email ? <p className="mb-0">
                          <strong>Email: </strong>
                          {address?.email}
                        </p> : <>
                          <p className="mb-0"><strong className="text-danger">Enter Email For Order Update**</strong></p>
                          <p className="mb-0 d-flex">
                              <Form.Control
                                type="text"
                                placeholder="Email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            <Button variant="warning" onClick={() => updateUserEmailAddress(email, address?._id, index)}>Save</Button>
                          </p>
                        </>} */}
                    </div>
                  </Container>
              </Col>
            ))}
          </Row>
        )}
        <Row>
          <Col>
            <Form.Check
              type="switch"
              id="custom-switch"
              checked={billingShippingStatus}
              onChange={() => setBillingShippingStatus(!billingShippingStatus)}
              label="Is the billing address the same as the shipping address?"
            />
          </Col>
        </Row>
       {(billingStatusLocal && billingFullAddress) && <Row>
          <Col md={12}>
            <h4 className="text-black">Billing Address</h4>
            {billingFullAddress?.firstName} {billingFullAddress?.lastName || ''}<br/>
            {billingFullAddress?.houseNo}<br/>
            {billingFullAddress?.addressline1} {billingFullAddress?.addressline2 || ''}<br/>
            {billingFullAddress?.city} - {billingFullAddress?.zipcode}<br/>
            Organisation Name: {billingFullAddress?.organisationName || ''} <br/>
            GST: {billingFullAddress?.gst || ''}<br/>
          </Col>
        </Row>}
        {!billingShippingStatus && <AddUserAddress billingShippingStatus={1} 
          addressIndex={selectedAddressIndex}
          firstName={getUserAddressList?.[selectedAddressIndex]?.firstName}
          lastName={getUserAddressList?.[selectedAddressIndex]?.lastName}
          addressline1={getUserAddressList?.[selectedAddressIndex]?.addressline1}
          addressline2={getUserAddressList?.[selectedAddressIndex]?.addressline2}
          city={getUserAddressList?.[selectedAddressIndex]?.city}
          zipcode={getUserAddressList?.[selectedAddressIndex]?.zipcode}
          state={getUserAddressList?.[selectedAddressIndex]?.state}
          phone={getUserAddressList?.[selectedAddressIndex]?.phone}
          email={getUserAddressList?.[selectedAddressIndex]?.email}
          houseNo={getUserAddressList?.[selectedAddressIndex]?.houseNo}
          btntext="Save Billing Address"
          onSendData={handleChildData}
          />
        }
        {((((billingStatusLocal === 1) || (localStorage.getItem("billingAdrs") === "1")) && !billingShippingStatus) || (billingShippingStatus && (selectedAddressIndex !== null))) && <Row>
          <Col md={12} className="my-3 ">
            <Button onClick={async() => {
               //facebook tracking
                if (typeof window.fbq !== "function") {
                  return;
                }
                const contents = getCartlistData.list.map((item) => ({
                  id: item?.product?.sku || "Unknown ID",
                  quantity: item?.quantity || 1,
                  price: item?.product?.sale || 0,
                  content_category: item?.product?.categories?.name || "Uncategorized",
                  content_name: item?.product?.name || "Unknown Product",
                }));
                window.fbq("track", "InitiateCheckout", {
                  contents,
                  content_type: "product",
                  value:amount,
                  currency: "INR",
                });
              //facebook tracking end
              if (window.dataLayer) {
                window.dataLayer.push({
                  event: 'InitiateCheckout',
                  buttonName: 'Pay Now',
                  ecommerce: {
                    value: amount, 
                    currency: 'INR',      
                    items: contents
                    }
                });
              }
              //data layer
                const apiResp = await dispatch(orderCreate(
                  {
                    addressIndex: selectedAddressIndex, 
                    // cartoffer:couponStatus,
                    cartoffer: (/true/).test(localStorage.getItem("cartoffer")),
                    couponName: localStorage.getItem("couponName"),
                    couponoffer: (/true/).test(localStorage.getItem("couponoffer")), 
                    code: localStorage.getItem("code"),
                    billingShippingAdrsSame: billingShippingStatus,
                    networkSpeed, 
                    connection: connectionType,
                    utm_source: localStorage.getItem("utm_source")?.length > 0 ? localStorage.getItem("utm_source") : 'Organic',
                    utm_Medium: localStorage.getItem("utm_Medium"),
                    utm_campaign: localStorage.getItem("utm_campaign"),
                    utm_adsetname: localStorage.getItem("utm_adsetname"),
                    utm_adname: localStorage.getItem("utm_adname")
                  }))
                  if(apiResp?.payload?.status){
                    window.location.replace(apiResp?.payload?.payurl);
                    localStorage.removeItem("code")
                    localStorage.removeItem("couponName")
                    localStorage.removeItem("billingAdrs")
                    localStorage.removeItem("utm_source")
                    localStorage.removeItem("utm_Medium")
                    localStorage.removeItem("utm_campaign")
                    localStorage.removeItem("utm_adsetname")
                    localStorage.removeItem("utm_adname")
                    localStorage.removeItem("couponName")
                    localStorage.removeItem("cartoffer")
                    localStorage.removeItem("couponoffer")
                    localStorage.removeItem("billingFullAdrs")
                    localStorage.removeItem('couponapplyName')
                   }
                  else if((selectedAddressIndex >= 0) && firstServicableIndex){
                    alert("Please select address.")
                  }
                  else{
                    // alert("Please Update Email in Your Address.")
                  }
                }} className="theme-btn w-100 ">Pay Now <i className="bi bi-arrow-right"></i></Button>
          </Col>
        </Row>}
      </div>
      <GotoTop/>
      <div className="margin">
      <Footer />
      </div> 
    </>
  );
};

export default UserAddress;
