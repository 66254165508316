import React from 'react'
import { Bar, Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import {CategoryScale} from 'chart.js'; 
import moment from 'moment';
Chart.register(CategoryScale);

export default function DashboardGraph({graphData, graphData2, title, chartType, type, labelType}) {
  const successOrderGraph = {
    labels: (graphData2?.length > graphData?.length
      ? graphData2?.map((item) => labelType === "hour" ? moment(item?._id).format('LTS') : item?._id)
      : graphData?.map((item) => labelType === "hour" ? moment(item?._id).format('LTS') : item?._id)),
    datasets: [
      {
        label: "Previous Period",
        data: graphData?.map((item) => 
          type === 1 ? item?.totalPayableAmount + item?.codChargesAmount :
          type === 2 ? item?.totalOrders : 
          null
        ) || [],
        fill: true,
        backgroundColor: "rgb(242 68 98 / 15%)",
        borderColor: "#b5496f",
        tension: 0.5,
        borderWidth: 1.5,
        pointBorderWidth: 0,
        pointRadius: 0,
      },
      {
        label: "Latest Period",
        data: graphData2?.map((item) => 
          type === 1 ? item?.totalPayableAmount + item?.codChargesAmount : 
          type === 2 ? item?.totalOrders : 
          null
        ) || [],
        fill: true,
        backgroundColor: "rgba(36, 160, 237, 0.2)", 
        borderColor: "#24a0ed",
        tension: 0.5,
        borderWidth: 1.5,
        pointBorderWidth: 0,
        pointRadius: 0,
      }
    ]
  };
  
  return (
    !chartType ? <Bar data={successOrderGraph} /> : <Line data={successOrderGraph} />
  )
}
