import React, { useState } from "react";
import { Form, Row, Col, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FieldArray, Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { crossSellingAdminList } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  status: yup.boolean().required(),
  category: yup.string().required(),
  id: yup.string(),
  name: yup.string().required(),
});

function CrossSellingAction({
  title,
  popUpTitle,
  id,
  category,
  api,
  crossSellProducts,
  status,
  name
}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const { allProductListCatWiseData } = useSelector(
    (state) => state.commonReducer
  );
  const { getCatsFrontEndData } = useSelector((state) => state.frontEndReducer);

  return (
    <>
      <Button className="bg-thememain" onClick={handleShow}>
        {title}
      </Button>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{popUpTitle}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);
            const apiResp = await dispatch(api(values));
            if (apiResp?.payload?.status) {
              setLoading(false);
              dispatch(crossSellingAdminList());
              handleClose();
            }
          }}
          initialValues={{
            category,
            name,
            crossSellProducts: id
              ? crossSellProducts?.map((item) => ({
                  product: item?.product?.id,
                  price: item?.price,
                  orderby: item?.orderby,
                }))
              : [{ product: "", price: "", orderby: "" }],
            id,
            status,
          }}
        >
          {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3 mt-3">
                <Col md={12} sm={12}>
                  <Form.Group controlId="validationFormik01">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6} sm={6}>
                  <Form.Group>
                    <Form.Label>Select Category</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      className="form-control bg"
                      name={`category`}
                      value={values.category}
                      isInvalid={!!errors.category}
                      // disabled={stateHide ? true : false}
                    >
                      <option value="">Select Category</option>
                      {!!getCatsFrontEndData &&
                        getCatsFrontEndData?.map((data, index) => {
                          return (
                            <option value={data?.id} key={index}>
                              {data?.name}
                            </option>
                          );
                        })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.category}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} sm={6}>
                  <Form.Group>
                    <Form.Label>Select Status</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("status", /true/.test(e.target.value));
                      }}
                      className="form-control bg"
                      name={`status`}
                      value={values.status}
                      isInvalid={!!errors.status}
                    >
                      <option value="">Select Status</option>
                      <option value={true}>Active</option>
                      <option value={false}>InActive</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.status}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Row>
                    <FieldArray name="crossSellProducts">
                      {({ remove, push }) => (
                        <>
                          {!!values.crossSellProducts &&
                            values.crossSellProducts?.map((data, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <Col md={12} sm={12}>
                                    <Form.Group className="mb-2">
                                      <Form.Label>Select Product</Form.Label>
                                      <Form.Control
                                        as="select"
                                        onChange={handleChange}
                                        className="form-control bg"
                                        name={`crossSellProducts.${index}.product`}
                                        value={data?.product}
                                        isInvalid={!!errors.state}
                                      >
                                        <option value="">Select Product</option>
                                        {!!allProductListCatWiseData &&
                                          allProductListCatWiseData?.map(
                                            (product, pindex) => {
                                              return (
                                                <>
                                                  {product?.categories !==
                                                    values.category && (
                                                    <option
                                                      value={product?.id}
                                                      key={pindex}
                                                    >
                                                      ({product?.sku}) -{" "}
                                                      {product?.name}
                                                    </option>
                                                  )}
                                                </>
                                              );
                                            }
                                          )}
                                      </Form.Control>
                                    </Form.Group>
                                  </Col>
                                  <Col md={3} sm={12}>
                                    <Form.Group>
                                      <Form.Label>Price</Form.Label>
                                      <Form.Control
                                        type="number"
                                        onChange={handleChange}
                                        value={data?.price}
                                        name={`crossSellProducts.${index}.price`}
                                        className="form-control bg"
                                        isInvalid={!!errors.price}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={3} sm={12}>
                                    <Form.Group>
                                      <Form.Label>Order By</Form.Label>
                                      <Form.Control
                                        type="number"
                                        onChange={handleChange}
                                        value={data?.orderby}
                                        name={`crossSellProducts.${index}.orderby`}
                                        className="form-control bg"
                                        isInvalid={!!errors.crossSellProducts}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={1} sm={12} className="mt-4">
                                    <Button
                                      variant="danger"
                                      onClick={() => remove(index)}
                                    >
                                      <i className="bi bi-trash3-fill"></i>
                                    </Button>
                                  </Col>
                                  <Col md={1} sm={12} className="mt-4">
                                    <Button
                                      variant="success"
                                      onClick={() =>
                                        push({
                                          product: "",
                                          price: "",
                                          orderby: "",
                                        })
                                      }
                                    >
                                      <i className="bi bi-plus-lg"></i>
                                    </Button>
                                  </Col>
                                </React.Fragment>
                              );
                            })}
                        </>
                      )}
                    </FieldArray>
                  </Row>
                </Col>
              </Row>
              <Modal.Footer>
                {loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <Button variant="success" type="submit">
                    Submit
                  </Button>
                )}
                <Button variant="danger" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default CrossSellingAction;
