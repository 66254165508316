import React, { useEffect } from "react";
import { Button, Col, Row, Table, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createNameSlipAndShipping,
  getOrderPdfFilters,
} from "../../reducers/commonReducer";
import moment from "moment";
import * as yup from "yup";
import FilterSettingAction from "./FilterSettingAction";
import { Formik } from "formik";

const schema = yup.object().shape({
  start: yup.string().required(),
  end: yup.string().required(),
});

function FilterList({ processing, title, api, data }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(api ? api() : getOrderPdfFilters());
  }, []);
  const { getOrderPdfFiltersList } = useSelector(
    (state) => state.commonReducer
  );
  return (
    <Row>
      <Col md={12}>
        <h3 className="text-black">
          <b>{title}</b>
        </h3>
      </Col>
      <Col md={12}>
        <Formik
          validationSchema={schema}
          onSubmit={async (values) => {
            const apiResp = await dispatch(createNameSlipAndShipping(values));
            if (apiResp?.payload?.status) {
              dispatch(getOrderPdfFilters());
            }
          }}
          initialValues={{
            start: "",
            end: "",
            processing,
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form className="p-0" onSubmit={handleSubmit}>
              <Table striped bordered hover className="w-100">
                <thead>
                  <tr>
                    <th className="text-center">Start Success Id</th>
                    <th className="text-center">End Success Id</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="SuccessID"
                          name="start"
                          value={values.start}
                          onChange={handleChange}
                          isInvalid={!!errors.start}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.start}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </td>
                    <td>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder="SuccessId"
                          name="end"
                          value={values.end}
                          onChange={handleChange}
                          isInvalid={!!errors.end}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.end}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </td>
                    <td>
                      <Button variant="success" type="submit">
                        Submit
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Form>
          )}
        </Formik>
      </Col>
      <Col md={12}>
        <Table striped={true} bordered hover>
          <thead>
            <tr className="text-center">
              <th>Name</th>
              <th>Start</th>
              <th>End</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data?.name ? data?.name : getOrderPdfFiltersList?.name}</td>
              <td>
                {moment(data?.startDate ? data?.startDate : getOrderPdfFiltersList?.startDate).format("YYYY-MM-DD")}
              </td>
              <td>
                {moment(data?.endDate ? data?.endDate : getOrderPdfFiltersList?.endDate).format("YYYY-MM-DD")}
              </td>
              <td>
                <FilterSettingAction
                  name={data?.name ? data?.name : getOrderPdfFiltersList?.name}
                  startDate={data?.startDate ? data?.startDate : getOrderPdfFiltersList?.startDate}
                  endDate={data?.endDate ? data?.endDate : getOrderPdfFiltersList?.endDate}
                  processing={processing}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

export default FilterList;
