import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../../Components/SideMenu";
import moment from "moment";
import { Button, Row, Form, Col, Table } from "react-bootstrap";
import {
  deleteOrderPdfFiles,
  getShippingSettings,
  resetProductList,
  shippingLogs,
} from "../../reducers/commonReducer";
import FilterList from "./FilterList";
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

function CustomToggle({ children, eventKey, setActiveKey, activeKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    setActiveKey(activeKey === eventKey ? null : eventKey)
  );

  return (
    <Button
      key={eventKey}
      variant="warning"
      type="button"
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
  );
}


const ShippingLogs = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    dispatch(resetProductList());
  }, []);
  useEffect(() => {
    dispatch(shippingLogs());
  }, []);

  const { getOrderPdfFilesList, shippingLogList, shippingLogListBlank, getShippingSettingsList } =
    useSelector((state) => state.commonReducer);

  //delete order pdf
  const deleteRecord = async() => {
    const apiResp = await dispatch(deleteOrderPdfFiles());
    if (apiResp?.payload?.status) {
      dispatch(shippingLogs());
    }
  };

  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <FilterList processing ={"shipping"} title={"Shipping"} api={getShippingSettings} data={getShippingSettingsList}/>
        {getOrderPdfFilesList?.files?.length ? (
          <Row>
            <Col>
              <Button
                onClick={deleteRecord}
                variant="danger"
                className="float-right"
              >
                Delete <i className="bi bi-trash3-fill"></i>
              </Button>
            </Col>
          </Row>
        ) : null}
        <hr />
        <Row className="mb-1">
          <Col md={3}>
            <Form.Group>
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                className="animated-search-input"
                type="date"
                placeholder="Start Date"
                name="startDate"
                max={moment().format("YYYY-MM-DD")}
                onChange={(e) => {
                  dispatch(resetProductList());
                  dispatch(
                    shippingLogs({
                      endDate,
                      startDate: e.target.value,
                      page,
                    })
                  );
                  setStartDate(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>End Date</Form.Label>
              <Form.Control
                className="animated-search-input"
                type="date"
                placeholder="End Date"
                name="endtDate"
                max={moment().format("YYYY-MM-DD")}
                onChange={(e) => {
                  dispatch(resetProductList());
                  dispatch(
                    shippingLogs({
                      endDate: e.target.value,
                      startDate,
                      page,
                    })
                  );
                  setEndDate(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col md={2} className="text-end mt-4">
            <Button
              variant="danger"
              className="animatedd-clear-btn"
              onClick={() => window.location.reload()}
            >
              Clear
            </Button>
          </Col>
        </Row>
        <Table striped className="table w-100">
          <thead>
            <tr className="tables">
              <th>Create</th>
              <th>Start</th>
              <th>End</th>
              <th>PickupLocation</th>
              <th>Shipments</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody className="tbody-table">
            {!!shippingLogList &&
              shippingLogList?.map((log, index) => {
                return (
                  <tr key={index}>
                    <td> {moment(log?.createdAt).format("DD/MM/YY")}</td>
                    <td className="button-pointer">
                      {Number(log?.start) ? Number(log?.start) : moment(log?.start).format("DD/MM/YY")}
                    </td>
                    <td className="button-pointer">
                    {Number(log?.end) ? Number(log?.end) : moment(log?.end).format("DD/MM/YY")}
                    </td>
                    <td className="button-pointer">
                      <b>{log?.pickup_location?.name}</b>
                      <br />
                      Phone: <b>{log?.pickup_location?.phone}</b>
                    </td>
                    <td className="button-pointer">
                      {log?.shipments?.map((ship, shipIndex) => {
                        return <span key={shipIndex}>{ship?.order}, </span>;
                      })}
                    </td>
                    <td className="button-pointer custom-design-tbl">
                    {log?.response?.length > 0 && <Accordion activeKey={activeKey}>
                    <CustomToggle eventKey={index} setActiveKey={setActiveKey} activeKey={activeKey}>Click me!</CustomToggle>
                      <Accordion.Collapse eventKey={index}>
                        <table className="custom-design-tbl">
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th>Remarks</th>
                              <th>Waybill</th>
                            </tr>
                          </thead>
                          <tbody>
                            {log?.response?.map((resp, respIndex) => (
                              <tr key={respIndex}>
                                <td>{resp?.status}</td>
                                <td>
                                  {resp?.remarks?.length > 0 ? (
                                    resp.remarks.map((remark, indexRemark) => (
                                      <span
                                        key={indexRemark}
                                        className="remark-item"
                                      >
                                        {remark}
                                        <br />
                                      </span>
                                    ))
                                  ) : (
                                    <span>No remarks</span>
                                  )}
                                </td>
                                <td>{resp?.waybill}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Accordion.Collapse>
                    </Accordion>}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {!!shippingLogListBlank && shippingLogListBlank?.length > 0 ? (
          <Row>
            <Button
              onClick={() => {
                setPage(page + 1);
                dispatch(
                  shippingLogs({
                    page: page + 1,
                    startDate,
                    endDate,
                  })
                );
              }}
            >
              Load More
            </Button>
          </Row>
        ) : (
          <p className="text-center">
            <b>No record found 😔.</b>
          </p>
        )}
      </div>
    </>
  );
};

export default ShippingLogs;
