import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  allApi,
  multiFileUpload,
  messages,
  postApi,
  postApiFile,
} from "../helpers/apiStructure";

const initialState = {
  localStorageCartItem: !!localStorage.getItem("cartItem")
    ? JSON.parse(localStorage.getItem("cartItem"))
    : [],
  shippingLogList: [],
  getDashBoardData: [],
  getHomePageSettingList: [],
  getAttributesData: [],
  getRolesData: [],
  getCheckoutDeatilsData: [],
  getUsersData: [],
  usersBlank: [],
  dashboardRecordData: [],
  getCategoriesData: [],
  getSocialMediaSettingsData: [],
  getBannersData: [],
  getSettingsData: [],
  getSettingData: [],
  getBrandsData: [],
  getCouponsData: [],
  applyCouponData: [],
  getPermissionsData: [],
  getReviewsData: [],
  getShippingsData: [],
  getTaxgstsData: [],
  getTransactionsData: [],
  getZipcodesData: [],
  getCartlistData: [],
  getProductsData: [],
  getSingleProductData: [],
  getNewProductData: [],
  productsListData: [],
  productsBlank: [],
  productRatingData: [],
  getAllStatesList: [],
  fetchAddressData: [],
  getTrackersData: [],
};

var baseUrl = "http://localhost:3000";

if (window.location.host) {
  baseUrl = window.location.protocol + "//" + window.location.host;
}
//reset products list
export const resetProductList = createAsyncThunk(
  "resetProductList",
  async (data) => {
    return [];
  }
);
//reset single page
export const resetSinglePage = createAsyncThunk(
  "resetSinglePage",
  async (data) => {
    return [];
  }
);
//resetUser list
export const resetUsersList = createAsyncThunk(
  "resetUsersList",
  async (data) => {
    return [];
  }
);
//resetCustomizeProductsImgDetails
export const resetCustomizeProductsImgDetails = createAsyncThunk(
  "resetCustomizeProductsImgDetails",
  async (data) => {
    return [];
  }
);
export const getDashBoard = createAsyncThunk("getDashBoard", async (data) => {
  const result = await allApi(`${baseUrl}/api/admin/dashboard`, "get");
  return result;
});
//Home page settings
export const getHomePageSetting = createAsyncThunk(
  "getHomePageSetting",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/home`, "get");
    return result;
  }
);
// Offers
export const applyCartOffer = createAsyncThunk(
  "applyCartOffer",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/cart/offer`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getOffersForAdmin = createAsyncThunk(
  "getOffersForAdmin",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/offers`, "post", body);
    return result;
  }
);
export const getOffers = createAsyncThunk("getOffers", async (body) => {
  const result = await postApi(`${baseUrl}/api/offer/list`, "post", body);
  return result;
});
export const catWiseOffers = createAsyncThunk("catWiseOffers", async (body) => {
  const result = await postApi(`${baseUrl}/api/active/offers`, "post", body);
  return result;
});
export const addOffer = createAsyncThunk("addOffer", async (body) => {
  const result = await postApi(`${baseUrl}/api/offer/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateOffer = createAsyncThunk("updateOffer", async (body) => {
  const result = await postApi(`${baseUrl}/api/offer/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const couponVerify = createAsyncThunk("couponVerify", async (body) => {
  const result = await postApi(`${baseUrl}/api/coupon/offer`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
// social media settings
export const getSocialMediaSettings = createAsyncThunk(
  "getSocialMediaSettings",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/social`, "post", body);
    return result;
  }
);
export const updateSocialMediaSettings = createAsyncThunk(
  "updateSocialMediaSettings",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/socialmedia/edit`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
//admin dashboard
export const dashboardRecord = createAsyncThunk("dashboardRecord", async (body) => {
    const result = await postApi(`${baseUrl}/api/counter/reports`, "post", body);
    return result;
  }
);
export const dashboardReporting = createAsyncThunk("dashboardReporting", async (body) => {
  const result = await postApi(`${baseUrl}/api/counter/last/report`, "post", body);
  return result;
}
);
export const dashboardGraphs = createAsyncThunk("dashboardGraphs", async (body) => {
  const result = await postApi(`${baseUrl}/api/counter/graph/reports`, "post", body);
  return result;
});
export const compareGraphRecords = createAsyncThunk("compareGraphRecords", async (body) => {
  const result = await postApi(`${baseUrl}/api/counter/graph`, "post", body);
  return result;
});
// users manage
export const getUsers = createAsyncThunk("getUsers", async (body) => {
  const result = await postApi(`${baseUrl}/api/users`, "post", body);
  return result;
});
export const getUserAllAddress = createAsyncThunk(
  "getUserAllAddress",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/user/address`, "post", body);
    return result;
  }
);
export const addUser = createAsyncThunk("addUser", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const userDropDownWithRole = createAsyncThunk(
  "userDropDownWithRole",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/users/dropdown`, "post", body);
    return result;
  }
);
//OTP
export const sendOtp = createAsyncThunk("sendOtp", async (body) => {
  const result = await postApi(`${baseUrl}/api/otp/send`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const otpVerification = createAsyncThunk(
  "otpVerification",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/otp/verify`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
//forgot password
export const sendVerificationCode = createAsyncThunk(
  "sendVerificationCode",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/email/otp`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const verifyOTP = createAsyncThunk("verifyOTP", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/user/forgetPassword`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const passwordChanges = createAsyncThunk(
  "passwordChanges",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/user/password/change`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
// roles manage
export const getRoles = createAsyncThunk("getRoles", async (data) => {
  const result = await allApi(`${baseUrl}/api/roles`, "get");
  return result;
});
//upload products
export const uploadProducts = createAsyncThunk(
  "uploadProducts",
  async (body) => {
    const result = await postApiFile(
      `${baseUrl}/api/product/upload`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
//upload Users
export const uploadUsers = createAsyncThunk("uploadUsers", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/users/upload`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
// Banners manage
export const addBanner = createAsyncThunk("addBanner", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/banner/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getBanners = createAsyncThunk("getBanners", async (data) => {
  const result = await allApi(`${baseUrl}/api/banners`, "get");
  return result;
});
export const deleteBanner = createAsyncThunk("deleteBanner", async (data) => {
  const result = await allApi(`${baseUrl}/api/banner/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
export const updateBanner = createAsyncThunk("updateBanner", async (body) => {
  const result = await postApiFile(
    `${baseUrl}/api/banner/edit/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
// brands manage
export const addBrand = createAsyncThunk("addBrand", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/brand/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getBrands = createAsyncThunk("getBrands", async (data) => {
  const result = await allApi(`${baseUrl}/api/brands`, "get");
  return result;
});
export const deleteBrand = createAsyncThunk("deleteBrand", async (data) => {
  const result = await allApi(`${baseUrl}/api/brand/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
export const updateBrand = createAsyncThunk("updateBrand", async (body) => {
  const result = await postApiFile(
    `${baseUrl}/api/brand/edit/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
// Create Product
export const CreateProduct = createAsyncThunk("CreateProduct", async (body) => {
  const result = await postApi(`${baseUrl}/api/product/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const customizeProductImages = createAsyncThunk(
  "customizeProductImages",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/add/inputs/image`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const customizeProductImagesEdit = createAsyncThunk(
  "customizeProductImagesEdit",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/edit/inputs/image`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const customizeProductClone = createAsyncThunk(
  "customizeProductClone",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/clone/custom/details`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const customizeProductsShortImg = createAsyncThunk(
  "customizeProductsShortImg",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/custom/details`, "post", body);
    return result;
  }
);
export const defaultSlideCustomProductsImg = createAsyncThunk(
  "defaultSlideCustomProductsImg",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/custom/image/select`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const deleteCustomizeProductsImg = createAsyncThunk(
  "deleteCustomizeProductsImg",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/custom/detail/remove`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const detailsCustomizeProductsImg = createAsyncThunk(
  "detailsCustomizeProductsImg",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/custom/detail`, "post", body);
    return result;
  }
);
export const updateProduct = createAsyncThunk("updateProduct", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/product/edit/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const addProductImages = createAsyncThunk(
  "addProductImages",
  async (body) => {
    const result = await multiFileUpload(
      `${baseUrl}/api/product/updateimage`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    // result?.status === 1 && (window.location.href = baseUrl + `/product-list`);
    // result?.status === 1 && localStorage.removeItem("productId");
    // localStorage.removeItem("uploadProductimg");
    // localStorage.removeItem("newProductapiStatus");
    return result;
  }
);
export const productsList = createAsyncThunk("productsList", async (body) => {
  const result = await postApi(`${baseUrl}/api/backend/products`, "post", body);
  return result;
});
export const allProductListCatWise = createAsyncThunk(
  "allProductListCatWise",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/allproducts`, "post", body);
    return result;
  }
);
export const similarproductions = createAsyncThunk(
  "similarproductions",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/similarproductions`,
      "post",
      body
    );
    return result;
  }
);
export const productClone = createAsyncThunk("productClone",async (body) => {
    const result = await postApi(`${baseUrl}/api/product/clone`,"post",body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const deleteProduct = createAsyncThunk("deleteProduct", async (data) => {
  const result = await allApi(`${baseUrl}/api/product/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
export const catWiseLatestProducts = createAsyncThunk(
  "catWiseLatestProducts",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/homepage/product`,
      "post",
      body
    );
    return result;
  }
);
export const updateProductQrCode = createAsyncThunk("updateProductQrCode", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/qrcode/upload`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const deleteQrCode = createAsyncThunk("deleteQrCode", async (body) => {
  const result = await postApi(`${baseUrl}/api/qrcode/remove`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
// coupon manage
export const addCoupon = createAsyncThunk("addCoupon", async (body) => {
  const result = await postApi(`${baseUrl}/api/coupon/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const applyCoupon = createAsyncThunk("applyCoupon", async (body) => {
  const result = await postApi(`${baseUrl}/api/coupon/apply`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getCoupons = createAsyncThunk("getCoupons", async (data) => {
  const result = await allApi(`${baseUrl}/api/coupons`, "get");
  return result;
});
export const deleteCoupon = createAsyncThunk("deleteCoupon", async (data) => {
  const result = await allApi(`${baseUrl}/api/coupon/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
export const updateCoupon = createAsyncThunk("updateCoupon", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/coupon/edit/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
//serial numbers
export const OrderSerialNumbers = createAsyncThunk(
  "OrderSerialNumbers",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/order/serialnumber`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
//order response
export const getORderResponse = createAsyncThunk(
  "getORderResponse",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/order/payment/status`,
      "post",
      body
    );
    return result;
  }
);
export const reTryPayment = createAsyncThunk("reTryPayment", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/order/payment/repay`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
// setting manage
export const addSetting = createAsyncThunk("addSetting", async (body) => {
  const result = await postApi(`${baseUrl}/api/setting/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getSettings = createAsyncThunk("getSettings", async (data) => {
  const result = await allApi(`${baseUrl}/api/settings`, "get");
  return result;
});
export const getSetting = createAsyncThunk("getSetting", async (body) => {
  const result = await postApi(`${baseUrl}/api/setting`, "post", body);
  return result;
});
export const getOpenSetting = createAsyncThunk(
  "getOpenSetting",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/settings/forcustomer`,
      "post",
      body
    );
    return result;
  }
);
export const deleteSetting = createAsyncThunk("deleteSetting", async (data) => {
  const result = await allApi(`${baseUrl}/api/setting/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
export const updateSetting = createAsyncThunk("updateSetting", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/setting/edit/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
// Permission manage
export const addPermission = createAsyncThunk("addPermission", async (body) => {
  const result = await postApi(`${baseUrl}/api/permission/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getPermissions = createAsyncThunk(
  "getPermissions",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/permissions`, "get");
    return result;
  }
);
export const deletePermission = createAsyncThunk(
  "deletePermission",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/permission/${data}`, "delete");
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updatePermission = createAsyncThunk(
  "updatePermission",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/permission/edit/${body?.id}`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
// Review manage
export const addReview = createAsyncThunk("addReview", async (body) => {
  const result = await postApi(`${baseUrl}/api/review/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getReviews = createAsyncThunk("getReviews", async (id) => {
  const result = await allApi(`${baseUrl}/api/reviews?page=${id}`, "get");
  return result;
});
export const deleteReview = createAsyncThunk("deleteReview", async (data) => {
  const result = await allApi(`${baseUrl}/api/review/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
export const updateReview = createAsyncThunk("updateReview", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/review/edit/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const siteReview = createAsyncThunk("siteReview", async (data) => {
  const result = await allApi(`${baseUrl}/api/approved/reviews`, "get");
  return result;
});
// Shippings manage
export const shippingLogs = createAsyncThunk("shippingLogs", async (body) => {
  const result = await postApi(`${baseUrl}/api/shipping/logs`, "post", body);
  return result;
});
export const createNameSlipAndShipping = createAsyncThunk("createNameSlipAndShipping", async (body) => {
  const result = await postApi(`${baseUrl}/api/createnameslip/andshipping`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const addShipping = createAsyncThunk("addShipping", async (body) => {
  const result = await postApi(`${baseUrl}/api/shipping/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getShippings = createAsyncThunk("getShippings", async (data) => {
  const result = await allApi(`${baseUrl}/api/shippings`, "get");
  return result;
});
export const deleteShipping = createAsyncThunk(
  "deleteShipping",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/shipping/${data}`, "delete");
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updateShipping = createAsyncThunk(
  "updateShipping",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/shipping/edit/${body?.id}`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
//cross selling
export const addCrossSelling = createAsyncThunk("addCrossSelling", async (body) => {
  const result = await postApi(`${baseUrl}/api/cross/sell/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateCrossSelling = createAsyncThunk("updateCrossSelling", async (body) => {
  const result = await postApi(`${baseUrl}/api/cross/sell/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const crossSellingAdminList = createAsyncThunk("crossSellingAdminList", async (body) => {
  const result = await postApi(`${baseUrl}/api/cross/sell/list`, "post", body);
  return result;
});
export const cartBaseCrossSelling = createAsyncThunk("cartBaseCrossSelling", async (body) => {
  const result = await postApi(`${baseUrl}/api/cartbasedsell`, "post", body);
  return result;
});
// reward slab
export const getRewardSlab = createAsyncThunk("getRewardSlab", async (body) => {
  const result = await postApi(`${baseUrl}/api/slab/list`, "post", body);
  return result;
});
export const addRewardSlab = createAsyncThunk("addRewardSlab", async (body) => {
  const result = await postApi(`${baseUrl}/api/slab/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateRewardSlab = createAsyncThunk(
  "updateRewardSlab",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/slab/edit/${body?.id}`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getOrderRewardSlab = createAsyncThunk(
  "getOrderRewardSlab",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/slab`, "post", body);
    return result;
  }
);
// trackers manage
export const addTracker = createAsyncThunk("addTracker", async (body) => {
  const result = await postApi(`${baseUrl}/api/track/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getTrackers = createAsyncThunk("getTrackers", async (data) => {
  const result = await allApi(`${baseUrl}/api/track/list`, "get");
  return result;
});
export const deleteTracker = createAsyncThunk("deleteTracker", async (data) => {
  const result = await allApi(`${baseUrl}/api/track/delete/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
export const updateTracker = createAsyncThunk("updateTracker", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/track/edit/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
//invoice pdf
export const getInvoicePdfFilters = createAsyncThunk(
  "getInvoicePdfFilters",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/invoice/setting`, "get");
    return result;
  }
);
export const filterInvoicePdfSettingCreate = createAsyncThunk(
  "filterInvoicePdfSettingCreate",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/invoice/processing`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const deleteInvoiceOrderPdfFiles = createAsyncThunk(
  "deleteInvoiceOrderPdfFiles",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/invoice/delete`, "post", data);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getInvoiceList = createAsyncThunk(
  "getInvoiceList",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/invoice/list`, "get");
    return result;
  }
);
export const orderIdWiseInvoice = createAsyncThunk(
  "orderIdWiseInvoice",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/invoice`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
//order pdf
export const getShippingSettings = createAsyncThunk(
  "getShippingSettings",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/shipping/setting`, "get");
    return result;
  }
);
export const getOrderPdfFilters = createAsyncThunk(
  "getOrderPdfFilters",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/pdf/setting/name`, "get");
    return result;
  }
);
export const getOrderPdfFiles = createAsyncThunk(
  "getOrderPdfFiles",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/pdffile/list`, "get");
    return result;
  }
);
export const deleteOrderPdfFiles = createAsyncThunk(
  "deleteOrderPdfFiles",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/pdffile/delete`, "post", data);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const filterPdfSettingCreate = createAsyncThunk(
  "filterPdfSettingCreate",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/createnameslip`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
//files Settings
export const addFileSetting = createAsyncThunk(
  "addFileSetting",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/file/setting/add`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updateFileSetting = createAsyncThunk(
  "updateFileSetting",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/file/setting/edit`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getFileSetting = createAsyncThunk(
  "getFileSetting",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/file/setting/list`, "get");
    return result;
  }
);
export const getFileSettings = createAsyncThunk(
  "getFileSettings",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/file/settings`, "get");
    return result;
  }
);
// product labels
export const addProductLabel = createAsyncThunk(
  "addProductLabel",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/label/add`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getProductLabel = createAsyncThunk(
  "getProductLabel",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/labels`, "get");
    return result;
  }
);
export const deleteProductLabel = createAsyncThunk(
  "deleteProductLabel",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/label/${data}`, "delete");
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updateProductLabel = createAsyncThunk(
  "updateProductLabel",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/label/edit/${body?.id}`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
// cod manage
export const getCod = createAsyncThunk("getCod", async (data) => {
  const result = await allApi(`${baseUrl}/api/cod/setting/list`, "get");
  return result;
});
export const addCod = createAsyncThunk("addCod", async (body) => {
  const result = await postApi(`${baseUrl}/api/cod/setting/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateCod = createAsyncThunk("updateCod", async (body) => {
  const result = await postApi(`${baseUrl}/api/cod/setting/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const deleteCod = createAsyncThunk("deleteCod", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/cod/settings/delete`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
// packages
export const addPackage = createAsyncThunk("addPackage", async (body) => {
  const result = await postApi(`${baseUrl}/api/package/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getPackage = createAsyncThunk("getPackage", async (data) => {
  const result = await allApi(`${baseUrl}/api/package/list`, "get");
  return result;
});
export const getActivePackage = createAsyncThunk(
  "getActivePackage",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/packages`, "get");
    return result;
  }
);
export const deletePackage = createAsyncThunk("deletePackage", async (data) => {
  const result = await allApi(`${baseUrl}/api/package/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
export const updatePackage = createAsyncThunk("updatePackage", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/package/edit/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
// warehouse manage
export const addWareHouse = createAsyncThunk("addWareHouse", async (body) => {
  const result = await postApi(`${baseUrl}/api/warehouse/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getWareHouse = createAsyncThunk("getWareHouse", async (data) => {
  const result = await allApi(`${baseUrl}/api/warehouse/list`, "get");
  return result;
});
export const deleteWareHouse = createAsyncThunk(
  "deleteWareHouse",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/warehouse/${data}`, "delete");
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updateWareHouse = createAsyncThunk(
  "updateWareHouse",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/warehouse/edit/${body?.id}`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
// taxgsts manage
export const addTaxgst = createAsyncThunk("addTaxgst", async (body) => {
  const result = await postApi(`${baseUrl}/api/taxgst/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getActiveTaxClass = createAsyncThunk(
  "getActiveTaxClass",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/frontend/taxgst`, "get");
    return result;
  }
);
export const getTaxgsts = createAsyncThunk("getTaxgsts", async (data) => {
  const result = await allApi(`${baseUrl}/api/taxgsts`, "get");
  return result;
});
export const deleteTaxgst = createAsyncThunk("deleteTaxgst", async (data) => {
  const result = await allApi(`${baseUrl}/api/taxgst/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
export const updateTaxgst = createAsyncThunk("updateTaxgst", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/taxgst/edit/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
// Transaction manage
export const addTransaction = createAsyncThunk(
  "addTransaction",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/transaction/add`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getTransactions = createAsyncThunk(
  "getTransactions",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/transactions`, "get");
    return result;
  }
);
export const deleteTransaction = createAsyncThunk(
  "deleteTransaction",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/transaction/${data}`, "delete");
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updateTransaction = createAsyncThunk(
  "updateTransaction",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/transaction/edit/${body?.id}`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
//checkout
export const getCheckoutDeatils = createAsyncThunk(
  "getCheckoutDeatils",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/checkout`, "get");
    return result;
  }
);
// cart manage
export const addCart = createAsyncThunk("addCart", async (body) => {
  const result = await postApi(`${baseUrl}/api/cart/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const addCartUploadImg = createAsyncThunk(
  "addCartUploadImg",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/cart/image/update`,
      "post",
      body
    );
    return result;
  }
);
export const getCartlist = createAsyncThunk("getCartlist", async (data) => {
  const result = await allApi(`${baseUrl}/api/cartlist`, "get");
  return result;
});
export const deleteCart = createAsyncThunk("deleteCart", async (data) => {
  const result = await allApi(`${baseUrl}/api/cart/${data}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
export const updateCart = createAsyncThunk("updateCart", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/cart/edit/${body?.id}`,
    "put",
    body
  );
  return result;
});
export const emptyCartlist = createAsyncThunk("emptyCartlist", async (data) => {
  const result = await allApi(`${baseUrl}/api/cart/empty`, "get");
  return result;
});
// product wise rating
export const getProductRating = createAsyncThunk(
  "getProductRating",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/reviewbyproducts`,
      "post",
      body
    );
    return result;
  }
);
// Product manage
export const getProducts = createAsyncThunk("getProducts", async (body) => {
  const result = await postApi(`${baseUrl}/api/products`, "post", body);
  return result;
});
export const getSingleProduct = createAsyncThunk(
  "getSingleProduct",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/getproduct`, "post", body);
    return result;
  }
);
// zipcodes manage
export const zipCodeVerify = createAsyncThunk("zipCodeVerify", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/shippingzone/servicable`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const addZipcode = createAsyncThunk("addZipcode", async (body) => {
  const result = await postApi(`${baseUrl}/api/zipcode/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getZipcodes = createAsyncThunk("getZipcodes", async (data) => {
  const result = await allApi(`${baseUrl}/api/zipcodes`, "get");
  return result;
});
// Categories manage
export const addCategorie = createAsyncThunk("addCategorie", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/category/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getCategories = createAsyncThunk("getCategories", async (body) => {
  const result = await postApi(`${baseUrl}/api/categories`, "post", body);
  return result;
});
export const deleteCategorie = createAsyncThunk(
  "deleteCategorie",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/category/${data}`, "delete");
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updateCategorie = createAsyncThunk(
  "updateCategorie",
  async (body) => {
    const result = await postApiFile(
      `${baseUrl}/api/category/edit/${body?.id}`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
// Attributes manage
export const addAttribute = createAsyncThunk(
  "updateAttribute",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/attribute/add`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getAttributes = createAsyncThunk("getAttributes", async (data) => {
  const result = await allApi(`${baseUrl}/api/attributes`, "get");
  return result;
});
export const deleteAttribute = createAsyncThunk(
  "deleteAttribute",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/attribute/${data}`,
      "delete",
      "Record is deleted successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updateAttribute = createAsyncThunk(
  "updateAttribute",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/attribute/edit/${body?.id}`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
//all states
export const getAllStates = createAsyncThunk("getAllStates", async (data) => {
  const result = await allApi(`${baseUrl}/api/states`, "get");
  return result;
});
// myprofile manage
export const userSignUp = createAsyncThunk("userSignUp", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/signup`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getMyProfile = createAsyncThunk("getMyProfile", async (data) => {
  const result = await allApi(`${baseUrl}/api/user/profile`, "get");
  return result;
});
export const userProdfile = createAsyncThunk("userProdfile", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/profile`, "post", body);
  return result;
});
export const signUpWithPhone = createAsyncThunk(
  "signUpWithPhone",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/user/verification`,
      "post",
      body,
      "Updated successfully."
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const editMyProfile = createAsyncThunk("editMyProfile", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/user/profile/edit`,
    "post",
    body,
    "Updated successfully."
  );
  await messages(result?.message, result?.status);
  return result;
});
// update profile form admin side
export const editProfileByAdmin = createAsyncThunk(
  "editProfileByAdmin",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/user/edit/${body?.id}`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const adminLogin = createAsyncThunk("adminLogin", async (body) => {
  const result = await postApi(`${baseUrl}/auth/login`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
// User address
export const userAddress = createAsyncThunk("userAddress", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/address/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateUserAddress = createAsyncThunk(
  "updateUserAddress",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/user/address/edit`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getUserAddressDetails = createAsyncThunk(
  "getUserAddressDetails",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/user/address`, "get");
    return result;
  }
);
export const orderAddressUpdate = createAsyncThunk(
  "orderAddressUpdate",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/order/address/update`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const deleteUserAddress = createAsyncThunk(
  "deleteUserAddress",
  async (data) => {
    const result = await allApi(
      `${baseUrl}/api/user/address/${data.id}/${data.index}`,
      "delete"
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const checkAddressArea = createAsyncThunk(
  "checkAddressArea",
  async (data) => {
    const result = await allApi(`${baseUrl}/api/address/servicable`, "get");
    return result;
  }
);
// zip code manage
export const zipcodeCityState = createAsyncThunk(
  "zipcodeCityState",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/zipcode/citystate`,
      "post",
      body
    );
    return result;
  }
);
//logout
export const userLogOut = createAsyncThunk("userLogOut", async (body) => {
  const result = await postApi(`${baseUrl}/auth/logout`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
const userReducer = createSlice({
  name: "details",
  initialState,
  reducers: {
    increaseLocalItem: (state) => {
      state.localStorageCartItem += 1;
    },
  },
  extraReducers: {
    [resetProductList.fulfilled]: (state, action) => {
      state.productsListData = [];
      state.getProductsData = [];
      state.getReviewsData = [];
      state.shippingLogList = [];
    },
    [resetSinglePage.fulfilled]: (state, action) => {
      state.getSingleProductData = [];
    },
    [resetUsersList.fulfilled]: (state, action) => {
      state.getUsersData = [];
    },
    [resetCustomizeProductsImgDetails.fulfilled]: (state, action) => {
      state.customizeProductsImgDetails = [];
    },
    [getHomePageSetting.fulfilled]: (state, action) => {
      state.getHomePageSettingList = action.payload;
    },
    [getAllStates.fulfilled]: (state, action) => {
      state.getAllStatesList = action.payload?.list;
    },
    //Shipping logs
    [shippingLogs.fulfilled]: (state, action) => {
          state.shippingLogList = [
            ...state?.shippingLogList,
            ...action.payload?.list,
          ];
          state.shippingLogListBlank = action.payload?.list;
        },
    //offers
    [getOffers.fulfilled]: (state, action) => {
      state.offersData = action.payload?.list;
    },
    [catWiseOffers.fulfilled]: (state, action) => {
      state.catWiseOffersList = action.payload?.offer;
    },
    [getOffersForAdmin.fulfilled]: (state, action) => {
      state.offersForAdminData = action.payload?.list;
    },
    //Get User address
    [getUserAddressDetails.fulfilled]: (state, action) => {
      state.getUserAddressList = action.payload?.address;
    },
    //get Address form Zip Code
    [zipCodeVerify.fulfilled]: (state, action) => {
      if (action.payload.status === 0) {
        localStorage.removeItem("state");
        localStorage.removeItem("city");
        localStorage.removeItem("pincode");
      }
    },
    [zipcodeCityState.fulfilled]: (state, action) => {
      state.zipcodeCityStateData = action.payload?.zipcode;
      if (action.payload.status === 1) {
        localStorage.setItem("state", action?.payload?.zipcode?.State);
        localStorage.setItem("city", action?.payload?.zipcode?.City);
        localStorage.setItem("pincode", action?.payload?.zipcode?.Pincode);
      }
    },
    //otp Verification
    [otpVerification.fulfilled]: (state, action) => {
      if (action.payload.status === 1) {
        localStorage.setItem("x-auth-token", action.payload.token);
        localStorage.setItem("userOldOrNew", action.payload.user);
      }
    },
    //social media settings
    [getSocialMediaSettings.fulfilled]: (state, action) => {
      state.getSocialMediaSettingsData = action.payload?.socialAccounts;
    },
    [getDashBoard.fulfilled]: (state, action) => {
      state.getDashBoardData = action.payload;
    },
    [getAttributes.fulfilled]: (state, action) => {
      state.getAttributesData = action.payload;
    },
    [CreateProduct.fulfilled]: (state, action) => {
      state.getNewProductData = action.payload?.product?.id;
      action.payload?.product?.id.length > 0 &&
        localStorage.setItem("productId", action.payload?.product?.id);
      action.payload?.status === 1 &&
        localStorage.setItem("newProductapiStatus", action.payload?.status);
    },
    [customizeProductsShortImg.fulfilled]: (state, action) => {
      state.customizeProductsShortImgList = action.payload?.list;
      state.defaultSlideSelected = action.payload?.defaultSample;
      state.imageRotate = action.payload?.rotate;
    },
    [detailsCustomizeProductsImg.fulfilled]: (state, action) => {
      state.customizeProductsImgDetails = action.payload?.custom;
    },
    // [productsList.fulfilled]: (state, action) => {
    //   state.productsListData = [
    //     ...state?.productsListData,
    //     ...action?.payload?.list,
    //   ];
    //   state.productsBlank = action.payload?.list;
    // },
    [productsList.fulfilled]: (state, action) => {
      state.productsListData = action.payload?.list;
      state.pageCount = action.payload?.count;
      state.productsBlank = action.payload?.list;
    },
    [catWiseLatestProducts.fulfilled]: (state, action) => {
      state.catWiseLatestProductsList = action.payload?.list;
      state.catWiseLatestProductsId = action.payload?.categoryIds;
    },
    [allProductListCatWise.fulfilled]: (state, action) => {
      state.allProductListCatWiseData = action.payload?.list;
    },
    [similarproductions.fulfilled]: (state, action) => {
      state.similarproductionsData = action.payload;
    },
    [getRoles.fulfilled]: (state, action) => {
      state.getRolesData = action.payload?.list;
    },
    [getUserAllAddress.fulfilled]: (state, action) => {
      state.userAllAddress = action.payload?.address;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.getUsersData = [...state.getUsersData, ...action.payload?.list];
      state.usersBlank = action.payload?.list;
    },
    [userDropDownWithRole.fulfilled]: (state, action) => {
      state.userDropDownWithRoleList = action.payload?.users;
    },
    [sendVerificationCode.fulfilled]: (state, action) => {
      action.payload?.status === 1 &&
        localStorage.setItem("sendVerificationCode", action.payload?.status);
    },
    [verifyOTP.fulfilled]: (state, action) => {
      action.payload?.status === 1 &&
        localStorage.setItem("verifyOTP", action.payload?.status);
    },
    [passwordChanges.fulfilled]: (state, action) => {
      action.payload?.status === 1 &&
        localStorage.setItem("passwordChanges", action.payload?.status);
    },
    [dashboardRecord.fulfilled]: (state, action) => {
      state.dashboardRecordData = action.payload?.list;
    },
    [dashboardReporting.fulfilled]: (state, action) => {
      state.dashboardReportingData = action.payload;
    },
    [dashboardGraphs.fulfilled]: (state, action) => {
      state.dashboardGraphsData = action.payload;
      state.dashboardGraphsAmount = action.payload?.amount;
    },
    [compareGraphRecords.fulfilled]: (state, action) => {
      state.compareGraphData = action.payload;
    },
    [getCategories.fulfilled]: (state, action) => {
      state.getCategoriesData = action.payload?.list;
    },
    [getBanners.fulfilled]: (state, action) => {
      state.getBannersData = action.payload;
    },
    [getBrands.fulfilled]: (state, action) => {
      state.getBrandsData = action.payload;
    },
    [getCheckoutDeatils.fulfilled]: (state, action) => {
      state.getCheckoutDeatilsData = action.payload;
    },
    [getCoupons.fulfilled]: (state, action) => {
      state.getCouponsData = action.payload;
    },
    [applyCoupon.fulfilled]: (state, action) => {
      state.applyCouponData = action.payload;
      action.payload?.status === 1 &&
        localStorage.setItem("couponCode", action.payload?.status);
      action.payload?.status === 1 &&
        localStorage.setItem("couponAmount", action.payload?.coupon?.amount);
    },
    [getORderResponse.fulfilled]: (state, action) => {
      state.getORderResponseStatus = action.payload;
    },
    [getSettings.fulfilled]: (state, action) => {
      state.getSettingsData = action.payload;
    },
    [getSetting.fulfilled]: (state, action) => {
      state.getSettingData = action.payload;
    },
    [getOpenSetting.fulfilled]: (state, action) => {
      state.getSettingData = action.payload;
    },
    [getPermissions.fulfilled]: (state, action) => {
      state.getPermissionsData = action.payload;
    },
    //
    [getReviews.fulfilled]: (state, action) => {
      state.getReviewsData = [
        ...state?.getReviewsData,
        ...action?.payload?.list,
      ];
      state.reviewBlank = action.payload?.list;
    },
    [siteReview.fulfilled]: (state, action) => {
      state.siteReviewList = action.payload?.list;
    },
    [getShippings.fulfilled]: (state, action) => {
      state.getShippingsData = action.payload;
    },
    [getTrackers.fulfilled]: (state, action) => {
      state.getTrackersData = action.payload;
    },
    [getFileSetting.fulfilled]: (state, action) => {
      state.getFileSettingList = action.payload?.list;
    },
    [getFileSettings.fulfilled]: (state, action) => {
      state.getFileSettingsList = action.payload?.list;
    },
    [getOrderPdfFiles.fulfilled]: (state, action) => {
      state.getOrderPdfFilesList = action.payload;
    },
    [getInvoicePdfFilters.fulfilled]: (state, action) => {
      state.getInvoicePdfFilterList = action.payload?.detail;
    },
    [getInvoiceList.fulfilled]: (state, action) => {
      state.getInvoiceData = action.payload?.files;
    },
    [getOrderPdfFilters.fulfilled]: (state, action) => {
      state.getOrderPdfFiltersList = action.payload?.detail;
    },
    [getShippingSettings.fulfilled]: (state, action) => {
      state.getShippingSettingsList = action.payload?.detail;
    },
    [getProductLabel.fulfilled]: (state, action) => {
      state.getProductLabelList = action.payload?.list;
    },
    [getCod.fulfilled]: (state, action) => {
      state.getCodList = action.payload?.setting;
    },
    [getPackage.fulfilled]: (state, action) => {
      state.getPackageList = action.payload?.list;
    },
    [getActivePackage.fulfilled]: (state, action) => {
      state.activePackageList = action.payload?.list;
    },
    [getWareHouse.fulfilled]: (state, action) => {
      state.wareHouseList = action.payload?.list;
    },
    [crossSellingAdminList.fulfilled]: (state, action) => {
      state.crossSellingAdminListing = action.payload?.list;
    },
    [cartBaseCrossSelling.fulfilled]: (state, action) => {
      state.cartBaseCrossSellingList = action.payload?.list;
    },
    [getRewardSlab.fulfilled]: (state, action) => {
      state.rewardSlabList = action.payload?.list;
    },
    [getOrderRewardSlab.fulfilled]: (state, action) => {
      state.orderRewardSlab = action.payload;
    },
    [getActiveTaxClass.fulfilled]: (state, action) => {
      state.activeTaxClassList = action.payload;
    },
    [getTaxgsts.fulfilled]: (state, action) => {
      state.getTaxgstsData = action.payload;
    },
    [getTransactions.fulfilled]: (state, action) => {
      state.getTransactionsData = action.payload;
    },
    [getZipcodes.fulfilled]: (state, action) => {
      state.getZipcodesData = action.payload;
    },
    [getCartlist.fulfilled]: (state, action) => {
      state.getCartlistData = action.payload;
    },
    [getProducts.fulfilled]: (state, action) => {
      state.getProductsData = action.payload?.list;
      state.catOfferBanner = action.payload?.offerBanner;
      state.catOfferBannerMbl = action.payload?.offerBannerMbl;
      state.pageCount = action.payload?.count;
    },
    // [getProducts.fulfilled]: (state, action) => {
    //   state.getProductsData = [
    //     ...state?.getProductsData,
    //     ...action?.payload?.list,
    //   ];
    //   state.shopProductsBlank = action.payload?.list;
    // },
    [getProductRating.fulfilled]: (state, action) => {
      state.productRatingData = action.payload?.list;
    },
    [getSingleProduct.fulfilled]: (state, action) => {
      state.getSingleProductData = action.payload?.product;
    },
    [getMyProfile.fulfilled]: (state, action) => {
      state.getMyProfileData = action.payload?.user;
    },
    [userProdfile.fulfilled]: (state, action) => {
      state.userProdfileData = action.payload?.me;
    },
    [editMyProfile.pending]: (state, action) => {
      state.editMyProfileMsg = "Please Wait......";
    },
    [editMyProfile.fulfilled]: (state, action) => {
      state.editMyProfileMsg = "Your profile is edit successfully.";
    },
    [editMyProfile.rejected]: (state, action) => {
      state.editMyProfileMsg = action?.error?.message;
    },
    [signUpWithPhone.fulfilled]: (state, action) => {
      // if (action.payload.status === 1) {
      //   localStorage.setItem("x-auth-token", action.payload.user.token);
      // }
    },
    [adminLogin.fulfilled]: (state, action) => {
      if (action.payload.status === 1) {
        if(action.payload?.me?.role?.slug === "admin" || action.payload?.me?.role?.slug === "checker"){
          localStorage.setItem("x-admin-token", action.payload.token);
        }else{
          localStorage.setItem("x-auth-token", action.payload.token);
        }
        localStorage.setItem("userRole", action.payload?.me?.role?.name);
        localStorage.setItem("slug", action.payload?.me?.role?.slug);
        localStorage.setItem("username", action.payload?.me?.email);
      }
    },
    [addProductImages.fulfilled]: (state, action) => {
      localStorage.setItem("uploadProductimg", action.payload?.status);
    },
  },
});

export const { increaseLocalItem } = userReducer.actions;
export default userReducer.reducer;
