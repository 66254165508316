import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";

const QtyWiseInput = ({ orderQty, inputs, setInputs, defaultValue, inputFields, setInputFields }) => {
  const [diffNameinMoreInput, setDiffNameinMoreInput] = useState(false);

useEffect(() => {
  setInputs(() => {
    return Array.from({ length: orderQty - 1 }, (_, i) => {
      const lastField = inputFields.length > 0
        ? { ...inputFields[inputFields.length - 1] }
        : { value: defaultValue };
      
      return {
        ...lastField,
        slug: `${lastField?.slug || "input"}-Qty-${i + 2}`,
      };
    });
  });
}, [orderQty, !diffNameinMoreInput && inputFields]); 

  // Handle input change
  const handleInputChange = (index, value) => {   
    setDiffNameinMoreInput(true);
    setInputs((prevInputs) => {
      const updatedInputs = [...prevInputs];
      const lastField = inputFields.length > 0 ? { ...inputFields[inputFields.length - 1] } : { value: defaultValue };
      lastField.value = value;
      lastField.slug = `${lastField?.slug}-Qty-${index + 2}`;
      updatedInputs[index] = lastField;
      return updatedInputs;
    });
  };
  
  // const addInput = () => {
  //   if (inputs.length < orderQty - 1) {
  //     setInputs([...inputs, defaultValue]);
  //   }
  // };

  // const removeInput = (index) => {
  //   setInputs((prevInputs) => prevInputs.filter((_, i) => i !== index));
  // };
  return (
    <>
      {inputs.map((input, index) => (
         <Col md={6} sm={6} xs={6} key={index} className="p-2 pt-0">
          <label>
            <strong className="text-dark">Box No. {index + 2}</strong>
          </label>
          <div className="">
            <input
              style={{
                width: "100%",
                color: "rgb(0, 0, 0)",
                fontSize: "16px",
                fontFamily: "Arial, sans-serif",
                fontWeight: "normal",
              }}
              type="text"
              // placeholder={`${defaultValue}`}
              required={input?.required}
              placeholder={`Enter Name`}
              value={input?.value || ''}
              onChange={(e) => handleInputChange(index, e.target.value)}
            />
           
            {(input?.required && (input.value?.length == 0 || input.value.trim() === "" || input.value.startsWith(" "))) ? (
                <span className="text-danger">This input field is required.</span>
              ) : null}
            {/* <Button
              variant="danger"
              onClick={() => removeInput(index)}
              style={{ marginLeft: "10px" }}
              className="py-0"
            >
              <i className="bi bi-trash3"></i>
            </Button> */}
          </div>
        </Col>
      ))}
      {/* <Row>
        <Col md={12}>
          {inputs.length < orderQty - 1 && (
            <Button variant="success" onClick={addInput}>
              Add More Inputs
            </Button>
          )}
        </Col>
      </Row> */}
    </>
  );
};

export default QtyWiseInput;

// import { useEffect } from "react";
// import { Col } from "react-bootstrap";

// const QtyWiseInput = ({ orderQty, inputs, setInputs, defaultValue, inputFields }) => {

//   useEffect(() => {
//     setInputs((prevInputs) => {
//       const newInputsLength = Math.max(0, orderQty - 1);

//       // Get lastField from inputFields if available, otherwise use defaultValue
//       const lastField = inputFields.length > 0
//         ? { ...inputFields[inputFields.length - 1] }
//         : { value: defaultValue };

//       // Preserve existing values & only append new ones if needed
//       const updatedInputs = [...prevInputs.slice(0, newInputsLength)];
//       while (updatedInputs.length < newInputsLength) {
//         updatedInputs.push({ ...lastField });
//       }

//       return updatedInputs;
//     });
//   }, [orderQty, inputFields]); // Dependencies: Update when orderQty or inputFields change

//   // Handle input change: update all fields with the same value
//   const handleInputChange = (value) => {
//     setInputs((prevInputs) => prevInputs.map((input) => ({ ...input, value })));
//   };

//   return (
//     <>
//       {inputs.map((input, index) => (
//         <Col md={6} sm={6} xs={6} key={index} className="p-2">
//           <label>
//             <strong className="text-dark">YOUR NAME</strong>
//           </label>
//           <div className="d-flex">
//             <input
//               style={{
//                 width: "100%",
//                 color: "rgb(0, 0, 0)",
//                 fontSize: "16px",
//                 fontFamily: "Arial, sans-serif",
//                 fontWeight: "normal",
//               }}
//               type="text"
//               placeholder={`Your Name ${index + 2}`}
//               value={input?.value || defaultValue}
//               onChange={(e) => handleInputChange(e.target.value)}
//             />
//           </div>
//         </Col>
//       ))}
//     </>
//   );
// };

// export default QtyWiseInput;

