import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideMenu from "../../Components/SideMenu";
import {
  addCrossSelling,
  addShipping,
  allProductListCatWise,
  crossSellingAdminList,
  deleteShipping,
  getAllStates,
  getShippings,
  updateCrossSelling,
  updateShipping,
} from "../../reducers/commonReducer";
import { Button, Col, Row } from "react-bootstrap";
import { home } from "../../const";
import CrossSellingAction from "./CrossSellingAction";
import { getCategoriesFrontEnd } from "../../reducers/frontEndReducer";

const CrossSellingList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("slug") === "admin" ? !localStorage.getItem("x-admin-token") : !localStorage.getItem("x-auth-token")) {
      navigate(home);
    }
    dispatch(getCategoriesFrontEnd());
    dispatch(allProductListCatWise());
    dispatch(crossSellingAdminList())
  }, []);

  const { getShippingsData, crossSellingAdminListing } = useSelector((state) => state.commonReducer);
  console.log("crossSellingAdminListing", crossSellingAdminListing)
  return (
    <>
      <SideMenu />
      <div className="mt-extra content container-fluid">
        <CrossSellingAction
          title={"Cross Selling"}
          popUpTitle="Add Cross Selling Category to Product"
          api={addCrossSelling}
        />
        <table className="table mt-2">
          <thead className="normalplace">
            <tr className="tables"> 
              <th className="col-1">Sr.</th>
              <th className="col-1">Name</th>
              <th className="col-5">Category</th>
              <th className="col-5">Products</th>
              <th className="col-5">Status</th>
              <th className="over-col-size">Action</th>
            </tr>
          </thead>
          <tbody className="tbody-table">
            {!!crossSellingAdminListing &&
              crossSellingAdminListing?.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr key={data?._id}>
                      <td>{index + 1}.</td>
                      <td>{data?.name}</td>
                      <td>{data?.category?.name}</td>
                      <td>
                        <tr className="border-black">
                          <th>Product Name</th>
                          <th>SKU</th>
                          <th>Price</th>
                          <th>OrderBy</th>
                        </tr>
                          {data?.crossSellProducts?.map((item, productIndex) => {
                            return (
                              <React.Fragment key={productIndex}>
                                <tr className="border-black">
                                  <td className="p-0">{item?.product?.name}</td>
                                  <td className="p-0">{item?.product?.sku}</td>
                                  <td className="p-0">{item?.price}/-</td>
                                  <td className="p-0">{item?.orderby}</td>
                                </tr>
                              </React.Fragment>
                            );
                          })}
                      </td>
                      <td>{data?.status ? <span className="text-success"><b>Active</b></span> : <span className="text-danger"><b>InActive</b></span>}</td>
                      <td>
                        <CrossSellingAction
                          title={<i className="bi bi-pencil-square"></i>}
                          popUpTitle="Update Tax Class"
                          api={updateCrossSelling}
                          id={data?.id}
                          status={data?.status}
                          crossSellProducts={data?.crossSellProducts}
                          category={data?.category?.id}
                          name={data?.name}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CrossSellingList;
