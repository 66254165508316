import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { allApi, messages, multiFileUpload, multipleImages, postApi, postApiFile } from "../helpers/apiStructure";
import { thankyou } from "../const";
import { object } from "yup";

const initialState = {
  zipcodeCityStateData: [],
  userAddressList: [],
  getUserAddress: [],
  shippingCostData: [],
  userOrdersList: [],
  userOrdersBlank: [],
  getUserProfileList: [],
  userWishListData: [],
  adminOrdersData: [],
  adminPendingOrdersData:[],
  adminOrdersBlank: [],
  singleOrderData: [],
  getProductReviewsList: [],
  postApiEmailTemplateList: [],
  userReviewsList: [],
  getEmailTemplatesList: [],
  userProductLikedData: [],
  userSignUpStatus: "",
  userAddressStatus: "",
};

var baseUrl = "http://localhost:3000";

if (window.location.host) {
  baseUrl = window.location.protocol + "//" + window.location.host;
}
//reset admin orders list
export const resetAdminOrderList = createAsyncThunk(
  "resetAdminOrderList",
  async (data) => {
    return [];
  }
);
//Email template
export const getEmailTemplates = createAsyncThunk(
  "getEmailTemplates",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/emailTemplates`, "post", body);
    return result;
  }
);
export const postApiEmailTemplate = createAsyncThunk(
  "postApiEmailTemplate",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/template`, "post", body);
    return result;
  }
);
export const updateEmailTemplate = createAsyncThunk(
  "updateEmailTemplate",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/emailTemplate/edit/${body.id}`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
//order trread
export const orderTimeline = createAsyncThunk("orderTimeline", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/statusdetails`, "post", body);
  return result;
});
// admin Order
export const adminOrders = createAsyncThunk("adminOrders", async (body) => {
  const result = await postApi(`${baseUrl}/api/admin/orders`, "post", body);
  return result;
});
export const orderDelete = createAsyncThunk("orderDelete", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/delete`, "post", body);
  return result;
});
export const adminPendingOrders = createAsyncThunk("adminPendingOrders", async (body) => {
  const result = await postApi(`${baseUrl}/api/admin/pending/orders`, "post", body);
  return result;
});
export const adminOrdersExport = createAsyncThunk("adminOrdersExport", async (body) => {
  const result = await postApi(`${baseUrl}/api/orders/forcsv`, "post", body);
  return result;
});
export const manualOrderShip = createAsyncThunk("manualOrderShip", async (body) => {
  const result = await postApi(`${baseUrl}/api/single/shipement`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const orderItemVerify = createAsyncThunk("orderItemVerify", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/orderItemVerify`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateOrderStatus = createAsyncThunk(
  "updateOrderStatus",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/admin/order/status/${body.id}`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const updatePaymentOrderStatus = createAsyncThunk("updatePaymentOrderStatus", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/pay/status/change`, "post", body);
  await messages(result?.message, result?.status);
  return result;
}
);
//single order details
export const singleOrder = createAsyncThunk("singleOrder", async (data) => {
  const result = await allApi(`${baseUrl}/api/order/detail/${data.id}`, "get");
  return result;
});
export const adminOrderComment = createAsyncThunk("adminOrderComment", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/addcomment`, "post", body);
  await messages(result?.message, result?.status);
  return result;
}
);
export const adminOrderCommentDelete = createAsyncThunk("adminOrderCommentDelete", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/commentdelete`, "post", body);
  await messages(result?.message, result?.status);
  return result;
}
);
export const singleOrderItem = createAsyncThunk("singleOrderItem", async (body) => {
    const result = await postApi(`${baseUrl}/api/order/product/details`, "post", body);
    return result;
  }
);
export const updateOrderItem = createAsyncThunk("updateOrderItem", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/item/update`, "post", body);
  await messages(result?.message, result?.status);
  return result;
}
);
//user product review
export const addUserProductReview = createAsyncThunk(
  "addUserProductReview", 
  async (body) => {
    const result = await multipleImages(`${baseUrl}/api/review/add`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const userReviews = createAsyncThunk("userReviews", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/review`, "post", body);
  return result;
});
export const getProductReviews = createAsyncThunk("getProductReviews", async (body) => {
  const result = await postApi(`${baseUrl}/api/reviews/product`, "post", body);
  return result;
});
//user wish list
export const userWishList = createAsyncThunk("userWishList", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/frontend/wishlist`,
    "post",
    body
  );
  return result;
});
export const userAddWishList = createAsyncThunk(
  "userAddWishList",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/addanddelete`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const userProductLikedList = createAsyncThunk(
  "userProductLikedList",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/userwishlist`, "post", body);
    return result;
  }
);
export const userWishListItemDelete = createAsyncThunk(
  "userWishListItemDelete",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/wishlist/${body.id}`,
      "delete",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
//reorder
export const createReOrder = createAsyncThunk(
  "createReOrder",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/order/recreate`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
// Order
export const orderCreateAdmin = createAsyncThunk("orderCreateAdmin", async (body) => {
  const result = await postApi(`${baseUrl}/api/admin/create/order`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const orderCreate = createAsyncThunk("orderCreate", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/create`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const userOrders = createAsyncThunk("userOrders", async (body) => {
  const result = await postApi(`${baseUrl}/api/orders`, "post", body);
  return result;
});
export const nextPrevOrderId = createAsyncThunk("nextPrevOrderId", async (body) => {
  const result = await postApi(`${baseUrl}/api/next/prev/order`, "post", body);
  if(result?.status === 0){
    await messages(result?.message, result?.status);
  }
  return result;
});

// shipping
export const shippingCost = createAsyncThunk("shippingCost", async (body) => {
  const result = await postApi(`${baseUrl}/api/shipping/cost`, "post", body);
  return result;
});
export const shippingCancel = createAsyncThunk("shippingCancel", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/shipment/cancel`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const reShipping = createAsyncThunk("reShipping", async (body) => {
  const result = await postApi(`${baseUrl}/api/single/reship`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
//user cart
export const userCartDetails = createAsyncThunk(
  "userCartDetails",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/carts`, "post", body);
    return result;
  }
);
const userReducer = createSlice({
  name: "orderDetails",
  initialState,
  reducers: {},
  extraReducers: {
    [resetAdminOrderList.fulfilled]: (state, action) => {
      // state.adminOrdersData = [];
      state.adminPendingOrdersData = [];
      state.userOrdersList = [];
      state.getProductReviewsList = [];
    },
    [getEmailTemplates.fulfilled]: (state, action) => {
      state.getEmailTemplatesList = action.payload.list;
    },
    [postApiEmailTemplate.fulfilled]: (state, action) => {
      state.postApiEmailTemplateList = action.payload?.list;
    },

    [shippingCost.fulfilled]: (state, action) => {
      state.shippingCostData = action.payload;
    },
    [userOrders.fulfilled]: (state, action) => {
      state.userOrdersList = [...state.userOrdersList, ...action.payload.list];
      state.userOrdersBlank = action.payload.list;
    },
    [singleOrder.fulfilled]: (state, action) => {
      state.singleOrderData = action.payload.order;
      state.orderCount = action.payload.counter;
      state.loginUserID = action.payload.user;
    },
    [singleOrderItem.pending]: (state) => {
      state.singleOrderItemloading = true;
    },
    [singleOrderItem.fulfilled]: (state, action) => {
      state.singleOrderItemloading = false;
      state.singleOrderItemData = action.payload?.productdetails;
    },
    [userWishList.fulfilled]: (state, action) => {
      state.userWishListData = action.payload?.list;
    },
    [adminOrders.fulfilled]: (state, action) => {
      state.pageCount = action.payload?.count;
      state.adminOrdersData = action.payload?.list;
      state.adminOrdersBlank = action.payload?.list;
    },
    [adminPendingOrders.fulfilled]: (state, action) => {
      state.adminPendingOrdersData = [
        ...state?.adminPendingOrdersData,
        ...action.payload?.list,
      ];
      state.adminPendingOrdersDataBlank = action.payload?.list;
    },
    [orderTimeline.fulfilled]: (state, action) => {
      state.orderTimelineData = action.payload?.orderStatusDetails;
    },
    [adminOrdersExport.fulfilled]: (state, action) => {
      state.adminOrdersExportList = action.payload?.list;
    },
    [getProductReviews.fulfilled]: (state, action) => {
      state.getProductReviewsList = [
        ...(Array.isArray(state.getProductReviewsList) ? state.getProductReviewsList : []),
        ...(Array.isArray(action.payload?.list) ? action.payload.list : []),
      ];
      state.productReviewCount = action?.payload?.count || 0;
      state.productReviewsListBlank = action.payload?.list || [];
    },
    [userReviews.fulfilled]: (state, action) => {
      state.userReviewsList = action.payload?.list;
    },
    [userProductLikedList.fulfilled]: (state, action) => {
      state.userProductLikedData = action.payload?.list;
    },
    [userCartDetails.fulfilled]: (state, action) => {
      state.userCartDetailsList = action.payload.carts
      state.pageCounts = action.payload.numberOfrecord
    },
    [orderCreate.fulfilled]: (state, action) => {
      state.orderCreateUrl = action.payload?.paymenturl;
    },
  },
});

export default userReducer.reducer;
