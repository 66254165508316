import React, { Fragment, useEffect, useRef, useState } from "react";
import Features from "../Sub/Features";
import Footer from "../Sub/Footer";
import OnSaleProducts from "../Sub/OnSaleProducts";
import WebSlider from "../Sub/WebSlider";
import Review from "../Sub/Review";
import Insta from "../Sub/Insta";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import btn from "../../images/Botton.png";

import {
  catWiseLatestProducts,
  getHomePageSetting,
  resetProductList,
  siteReview,
} from "../../reducers/commonReducer";
import Header from "../Sub/Header";
import Categories from "../Sub/Categories";
import SingleProduct from "../Sub/SingleProduct";
import { Link } from "react-router-dom";
import { calculateImageSize, shop } from "../../const";
import Loader from "../../Components/Loader";
import ScrollHeight from "../../Components/ScrollHeight";
import Skeleton from "react-loading-skeleton";


const Home = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [delayLoading, setDelayLoading] = useState(true);
  const width = useRef(window.innerWidth);
  useEffect(() => {
    dispatch(resetProductList());
    setLoading(true);
    setDelayLoading(true);
    const { cheight, cwidth } = calculateImageSize(width.current, 4);
    dispatch(
      catWiseLatestProducts({
        width: cwidth,
        height: cheight,
      })
    );
    setLoading(false);
  }, [width.current]);
  useEffect(() => {
    dispatch(getHomePageSetting());
    dispatch(siteReview());
  }, []);
  //loader showing after loading true
  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => {
        setDelayLoading(false);
      }, 250);
      return () => clearTimeout(timer);
    }
  }, [loading]);
  const {
    getHomePageSettingList,
    catWiseLatestProductsList,
    catWiseLatestProductsId,
    siteReviewList,
  } = useSelector((state) => state.commonReducer);
  const { getCountProductData } = useSelector((state) => state.frontEndReducer);
  const formatString = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <>
      <Header />
      <ScrollHeight loading={delayLoading} />
      <Categories />
      {!!getHomePageSettingList?.home?.show_main_slider && (
        <div className="container-fluid mt-3 mbl-margin-0">
          <WebSlider />
        </div>
      )}
      {!!getHomePageSettingList?.home?.showwebfeatures && (
        <Features mobileHide={"mbl-hide"} />
      )}
      <Container >
        {!catWiseLatestProductsList ? (
          <Row className="mt-3">
            {[...Array(4)].map((_, index) => (
              <Col key={index} md={3} sm={6} xs={6} className="text-center">
                <Skeleton style={{ height: "20vh", width: "100%" }} />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </Col>
            ))}
          </Row>
        ) : (
          Object.entries(catWiseLatestProductsList).map(([cat, products]) => {
            const formattedCat = formatString(cat);
            return (
              <Fragment key={formattedCat}>
                <Row id="single">
                  <Col md={12}>
                    <div className="section__head d-md-flex justify-content-between my-3">
                      <div className="section__title d-flex justify-content-between">
                        <h3>{formattedCat}</h3>
                      </div>
                      <div className="btn-my">
                        <Link
                          to={`${shop}/${cat}?ref_=${catWiseLatestProductsId?.[cat]}&catname=${formattedCat}`}
                        >
                          <img src={btn} alt={formattedCat}
                          style={{
                            height: "30px",
                            width: "auto"
                          }}
                          loading="lazy"
                          />
                        </Link> 
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  {products?.map((item, index) => (
                    <Col md={3} xs={6} sm={6} key={index}>
                      <SingleProduct
                        title={item?.name}
                        productType={item?.productType}
                        mrp={item?.mrp}
                        sale={item?.sale}
                        slug={item?.slug}
                        productImg={item?.productImg}
                        defaultImage={item?.defaultImage}
                        images={item?.images}
                        customizeDetails={item?.customizeDetails}
                        rating={item?.avgRating}
                        status={item?.status}
                      />
                    </Col>
                  ))}
                </Row>
              </Fragment>
            );
          })
        )}
      </Container>
      {/* <-----Cat Wise Product end-----> */}
      {/* {getHomePageSettingList?.home?.showlatestproduct && (!getCountProductData?.list ? (
        <Row className="mt-3">
          {[...Array(4)].map((_, index) => (
            <Col key={index} md={3} sm={6} xs={6} className="text-center">
              <Skeleton style={{ height: "20vh", width: "100%" }} />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Col>
          ))}
        </Row>
      ) : (
        <Container className="latest-products">
          <OnSaleProducts
            productsDetails={getCountProductData}
            title={"Latest Products"}
          />
        </Container>
      ))} */}
       {getHomePageSettingList?.home?.showlatestproduct &&  <Container className="latest-products">
          <OnSaleProducts
            productsDetails={getCountProductData}
            title={"Latest Products"}
          />
        </Container>}
      {!!getHomePageSettingList?.home?.review && (
        <Review siteReviewList={siteReviewList} />
      )}
      {/* {delayLoading ? (
              "<Loader />"
            ) : (
              <>
                <div className="container" >
                <Insta />
                </div> 
              </>
            )} */}
      {!delayLoading && <Footer />}
    </>
  );
};

export default Home;
